/* Roboto Condensed Regular */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./RobotoCondensed-Regular.woff2') format('woff2');
}

/* Roboto Condensed Light */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./RobotoCondensed-Light.woff2') format('woff2');
}

/* Roboto Condensed Bold */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./RobotoCondensed-Bold.woff2') format('woff2');
}
