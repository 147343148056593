body {
  margin: 0;
  background: #1d1d1d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

h1,
h2,
h3,
p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.css-1uccc91-singleValue {
  color: #ffffff !important;
}

.css-87mmbt-control,
.css-oicrv4-control {
  border-color: transparent !important;
  box-shadow: none !important;
}

.css-87mmbt-control:hover,
.css-oicrv4-control:hover {
  border-color: transparent !important;
  box-shadow: none !important;
}

.css-tj5bde-Svg {
  stroke: #ff3366 !important;
  fill: #ff3366 !important;
}

.css-2b097c-container,
.css-g1d714-ValueContainer {
  outline: none !important;
}

.css-26l3qy-menu {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.css-1hb7zxy-IndicatorsContainer {
  outline: none !important;
}

.swiper-button-next-unique,
.swiper-button-prev-unique {
  height: 250px !important;
  width: 50px !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
  transition: all 0.4s ease-in-out;
}

.swiper-button-prev-unique {
  background-image: url("assets/icons/prev-arrow.png");
}

.swiper-button-next-unique {
  background-image: url("assets/icons/next-arrow.png");
}

.swiper-button-next-unique:hover,
.swiper-button-prev-unique:hover {
  transform: scale(1.1);
}

.swiper-button-disabled {
  display: block !important;
  opacity: 0.2;
  pointer-events: none;
}

.swiper-slide-next,
.swiper-slide-prev {
  opacity: 0.1;
}

.swiper-slide-active {
  opacity: 1;
  transition: all 1.5s ease-in-out;

  animation: appearance 1s ease-in;

  @keyframes appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.swiper-button-disabled {
  display: none;
}

.weather-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* media */

@media screen and (max-width: 767px) {
  .swiper-button-next-unique,
  .swiper-button-prev-unique {
    width: 30px !important;
  }
}


.css-b8ldur-Input {
  color: #ffffff !important;
}

/* news feed */

.newsSlide a {
color: #ffffff;
font-size: 1.03rem;
text-align: center;
animation: appearance 1.2s ease-in;
margin-bottom: 20px;
}

.newsSlide:hover a {
  text-decoration: underline;
}


@keyframes appearance {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }


  .Toastify__toast--success {
    background: #000000 !important;
    color: #ffffff !important;
  }

  .Toastify__toast--error {
    background: #ff3366 !important;
    color: #1d1d1d !important;
  }

  .Toastify__progress-bar {
    margin-bottom: 2px;
  }

  .Toastify__progress-bar--error {
    background: #1d1d1d !important;
  }

  .Toastify__close-button--error {
    color: #1d1d1d !important;
  }